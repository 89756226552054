<template>
  <div>
    <vs-row class="justify-between">
      <vs-col vs-w="6">
        <vs-row>
          <vs-col vs-w="11">
            <vx-card class="pb-2">
              <vs-row>
                <vs-col vs-w="6">
                  <div
                    :class="'bg-primary' "
                    class="h-3 w-3 inline-block rounded-full mr-3 mt-2"
                  />
                  <span class="mr-3 pr-10"><b>الحجوزات الكلية :</b></span>
                </vs-col>
                <vs-col vs-w="6">
                  <h1 class="mr-3 pl-10">
                    <b>{{ total }}</b>
                  </h1>
                </vs-col>
              </vs-row>
            </vx-card>
            <div
              :key="item.id"
              class="mt-2"
              v-for="item in statistics"
            >
              <vx-card class="pb-2">
                <vs-row>
                  <vs-col vs-w="6">
                    <div
                      :class="'bg-primary' "
                      class="h-3 w-3 inline-block rounded-full mr-3 mt-2"
                    />
                    <span class="mr-3 pr-10"><b>{{ item.key }} :</b></span>
                  </vs-col>
                  <vs-col vs-w="6">
                    <h3
                      :style="'color: ' + item.color"
                      class="mr-3 pl-10"
                    >
                      <b>{{ item.value }}</b>
                    </h3>
                  </vs-col>
                </vs-row>
                <div
                  v-if="item.key=== 'الحجوزات المدفوعة'"
                  class="pb-2 mt-4"
                >
                  <div
                    :key="item.id"
                    class="mt-2"
                    v-for="item in statisticsForPaid"
                  >
                    <vs-row>
                      <vs-col vs-w="6">
                        <span class="mr-3 pl-5"><b>{{ item.title }} </b> <b :style="'color: ' + item.color">{{ item.key }} </b></span>
                      </vs-col>
                      <vs-col vs-w="6">
                        <h3
                          :style="'color: ' + item.color"
                          class="mr-3 pl-10"
                        >
                          <b>{{ item.value }}</b>
                        </h3>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
              </vx-card>
            </div>
            <vx-card
              class="mt-4"
              style="background-color: #FAFAFA"
            >
              <vs-row>
                <vs-col vs-w="6">
                  <div
                    :class="'bg-primary' "
                    class="h-3 w-3 inline-block rounded-full mr-3 mt-4"
                  />
                  <span class="mr-3 pr-10"><b>الحجوزات المحذوفة :</b></span>
                </vs-col>
                <vs-col vs-w="6">
                  <h1 class="mr-3 pl-10">
                    <b>{{ deleted }}</b>
                  </h1>
                </vs-col>
              </vs-row>
            </vx-card>
          </vs-col>
        </vs-row>
      </vs-col>
      <vs-col
        vs-w="6"
      >
        <vx-card class="mb-1">
          <vs-row>
            <vs-col vs-w="6">
              <span class="mr-3 pr-10"><b>الرسم البياني</b></span>
            </vs-col>
          </vs-row>
        </vx-card>
        <vx-card class="pb-2">
          <chartjs-component-pie-chart
            :data="firstChartData"
            :height="150"
            :options="options"
            v-if="statistics.length > 0"
          />
        </vx-card>
        <vx-card class="mt-2">
          <chartjs-component-pie-chart
            :data="pieChartData"
            :height="150"
            :options="pieChartOptions"
            v-if="statistics.length > 0"
          />
        </vx-card>
        <vx-card class="mt-2">
          <chartjs-component-pie-chart
            :data="pieChartDataForPaid"
            :height="150"
            :options="options"
            v-if="statisticsForPaid.length > 0"
          />
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import {RepositoryFactory} from '../event-repositories/eventRepositoryFactory';
import ChartjsComponentPieChart from '../event-components/ChartjsComponentPieChart.vue';

const eventsDefinitionRepo = RepositoryFactory.get("eventsDefinitionRepository");
const eventsRepo =  RepositoryFactory.get("eventsRepository");

export default {
  name: 'App',
  components: {
    ChartjsComponentPieChart
  },
  data() {
    return {
      paid: 0,
      not_paid: 0,
      invitation: 0,
      used: 0,
      active: 0,
      canceled: 0,
      options: {},
      statistics: [],
      statisticsForPaid:[],
      deleted: 0,
      total: 0,
      pieChartOptions: {},
    };
  },
  computed: {
    firstChartData() {
      return {
        labels: ["الحجوزات المدفوعة", "الحجوزات غير المدفوعة", 'الدعوات'],
        datasets: [
          {
            label: '',
            backgroundColor: ['#ABD376', '#F5C15A', '#6A99E5'],
            data: [this.paid.total, this.not_paid, this.invitation]
          }
        ]
      };
    },
    pieChartData() {
      return {
        labels: ["الحجوزات النشطة", "الحجوزات المستخدمة", 'الحجوزات الملغاة'],
        datasets: [
          {
            label: '',
            backgroundColor: ['#3D324C', '#82C18C', '#BF5062'],
            data: [this.active, this.used, this.canceled]
          }
        ]
      };
    },
    pieChartDataForPaid(){
        return {
          labels: ['نقداً','Syriatel mobile','MTN Cash','Fatora'],
          datasets: [
            {
              label: '',
              backgroundColor: ['#ABD376','#BF5062', '#F5C15A', '#6A99E5'],
              data: [this.paid.cash,this.paid.syriatel, this.paid.mtn, this.paid.fatora]
            }
          ]
        };
      },
    },
  methods: {
    fetchStatistics() {
      const repo = this.$route.path.includes('dailyStatistics')? eventsRepo :  eventsDefinitionRepo;
      repo.fetchStatistics(this.$route.params.id).then((data) => {
        this.deleted = data.deleted;
        this.total = data.total;
        Object.keys(data).forEach(s => {
          switch (s) {
            case  'active': {
              this.statistics.push({
                key: 'الحجوزات النشطة',
                value: data.active,
                percent: data.active,
                color: '#3D324C'
              });
              this.active = data.active;
              break;
            }
            case  'invitation': {
              this.statistics.push({
                key: 'الدعوات',
                value: data.invitation,
                percent: data.invitation,
                color: '#6A99E5'
              });
              this.invitation = data.invitation;
              break;
            }
            case  'not_paid': {
              this.statistics.push({
                key: 'الحجوزات غير المدفوعة',
                value: data.not_paid,
                percent: data.not_paid,
                color: '#F5C15A'
              });
              this.not_paid = data.not_paid;
              break;
            }
            case'paid': {
              this.statistics.push({
                key: 'الحجوزات المدفوعة',
                value: data.paid.total,
                percent: data.paid.total,
                color: '#ABD376'
              });
              this.paid = data.paid;
              this.fetchStatisticsForPaid(data.paid);
              break;
            }
            case  'used': {
              this.statistics.push({
                key: 'الحجوزات المستخدمة',
                value: data.used,
                percent: data.used,
                color: '#82C18C'
              });
              this.used = data.used;
              break;
            }
            case 'canceled': {
              this.statistics.push({
                key: 'الحجوزات الملغاة',
                value: data.canceled,
                percent: data.canceledو,
                color: '#BF5062'
              });
              this.canceled = data.canceled;
              break;
            }
            default:
              break;
          }
        });
        if(!data.hasOwnProperty('canceled'))
          this.statistics.push({
            key: 'الحجوزات الملغاة',
            value: this.canceled,
            percent: this.canceledو,
            color: '#BF5062'
          });
      });
    },
    fetchStatisticsForPaid(data){
      Object.keys(data).forEach(s => {
        switch (s) {
          case 'total':{
            this.paid.total = data.total;
            break;
          }
          case  'fatora': {
            this.paid.fatora = data.fatora;
            this.statisticsForPaid.push({
              key: 'Fatora',
              value: data.fatora,
              percent:data.fatora,
              color: '#6A99E5',
              title:'الدفع عبر'
            });
            break;
          }
          case  'syriatel': {
            this.paid.syriatel = data.syriatel;
            this.statisticsForPaid.push({
              key: 'Syriatel mobile',
              value: data.syriatel,
              percent: data.syriatel,
              color: '#BF5062',
              title:'الدفع عبر'
            });
            break;
          }
          case'mtn': {
            this.paid.mtn = data.mtn;
            this.statisticsForPaid.push({
              key: 'MTN Cash',
              value: data.mtn,
              percent: data.mtn,
              color: '#F5C15A',
              title:'الدفع عبر'
            });
            break;
          }
          case  'cash': {
            this.paid.cash = data.cash;
            this.statisticsForPaid.push({
              key: 'نقداً',
              value: data.cash,
              percent: data.cash,
              color: '#ABD376',
              title:'الدفع'
            });
            break;
          }
          default:
            break;
        }
      });
    },
  },
  created() {
    this.fetchStatistics();
  }
};
</script>
